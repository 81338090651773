<template>
    <section id="card-navigation">
      <b-row>
        <!-- pill tabs inside card -->
        <b-col md="12">
          <b-overlay
            :show="$apollo.loading"
            rounded="sm"
            spinner-variant="primary"
          >
            <b-card
              class="text-center"
            >
              <b-card-header
                class="justify-content-bewteen align-items-center p-0 pb-1 mb-1 border-bottom"
              >
                <div class="d-flex align-items-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    @click="$router.back()"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="ChevronLeftIcon"
                    />
                  </b-button>
                  <h3 class="mb-0 ml-2 d-flex justify-content-between">
                    <span>Manage {{ program.programs_startupparticipants[0].programs_applicantstable.users_organizationtable.title }}</span>
                  </h3>
                </div>
                <h5 class="text-primary">
                  <span>{{ program.title }}</span>
                </h5>
              </b-card-header>
              <b-tabs
                v-model="activeTab"
                class="h-scroll-tabs"
                nav-class="mb-2"
                pills
                @input="updateHash"
              >
                <b-tab
                  active
                  class="text-left"
                  href="Growth"
                  lazy
                  title="Growth"
                >
                  <GrowthManager/>
                </b-tab>
                <b-tab
                  class="text-left"
                  href="Exercises"
                  lazy
                  title="Exercises"
                >
                Exercises
                  <!-- <mentor-manager /> -->
                </b-tab>
                <b-tab
                  href="Milestones"
                  lazy
                  title="Milestones"
                >
                Milestones
                  <!-- <b-card
                    class="text-left"
                    title="Startup Progress"
                  >
                    <startup-progress />
                  </b-card> -->
                </b-tab>
                <b-tab
                  href="Feedbacks"
                  lazy
                  title="Feedbacks"
                >
                  <!-- <b-card
                    class="text-left"
                    title="Exercise Manager"
                  >
                    <review-manager />
                  </b-card> -->
                </b-tab>
                <b-tab
                  href="Requests"
                  lazy
                  title="Requests"
                >
                  <!-- <b-card
                    class="text-left"
                    title="Growth Manager"
                  >
                    <progress-stages />
                  </b-card> -->
                </b-tab>
                <b-tab
                  href="Emeets"
                  lazy
                  title="Sessions"
                >
                  <!-- <b-card
                    class="text-left"
                    title="Knowledge Base"
                  >
                    <knowledge-base />
                  </b-card> -->
                </b-tab>
              </b-tabs>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import gql from 'graphql-tag'
import GrowthManager from '@/views/incubator-portal/programs/pages/startups/program-manager/master-manager/growth.vue'
// import MentorManager from '@/views/incubator-portal/programs/pages/common/program-manager/MentorManager.vue'
// import Scheduler from '@/views/incubator-portal/programs/pages/common/program-manager/Scheduler.vue'
// import StartupProgress from '@/views/incubator-portal/programs/pages/startups/program-manager/StartupProgress.vue'
// import ReviewManager from '@/views/incubator-portal/programs/pages/startups/program-manager/ReviewManager.vue'
// import RequestsManager from '@/views/incubator-portal/programs/pages/startups/program-manager/RequestsManager.vue'
// import FeedbackManager from '@/views/incubator-portal/programs/pages/startups/program-manager/FeedbackManager.vue'
// import EmeetManager from '@/views/incubator-portal/programs/pages/startups/program-manager/EmeetManager.vue'
// import KnowledgeBase from '@/views/incubator-portal/programs/pages/common/program-manager/KnowledgeBase.vue'
// import ProgressStages from '@/views/incubator-portal/programs/pages/common/program-manager/ProgressStages.vue'

export default {
  components: {
    GrowthManager,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BTab,
    BTabs,
    BButton,
    // MentorManager,
    // Scheduler,
    // KnowledgeBase,
    // ProgressStages,
    // RequestsManager,
    // FeedbackManager,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      program: '',
      activeTab: 0,
      tabs: ['#Growth',
        '#Exercises',
        '#Milestones',
        '#Feedbacks',
        '#Requests',
        '#Emeets'],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
        programs_basicinfo_by_pk(id: "${this.$route.params.pid}"){
          id
          title
          begin_date
          programs_startupparticipants(where: {id: {_eq: ${this.$route.params.aid}}}) {
            programs_applicantstable {
              users_organizationtable {
                title
              }
            }
          }
        }
      }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
